<template>
  <div>
    <a-menu
      mode="horizontal"
      style="
        height: 64px;
        line-height: 64px;
        font-weight: 700;
        font-size: 16px;
        background-color: rgb(255, 249, 243);
        border-bottom: 0px;
        box-shadow: 0px -3px 23px -3px #a6cfc6;
      "
      @click="menuClick"
    >
      <a-menu-item
        key="logoPic"
        style="float: left; text-align: left; padding-left: 0px"
        disabled
      >
        <div class="logoDiv">
          <img id="logoImg" src="../../assets/img/logo.png" />
        </div>
      </a-menu-item>
      <a-menu-item key="home">
        {{ $t("header.home") }}
      </a-menu-item>
      <a-menu-item key="price">
        {{ $t("header.Price") }}
      </a-menu-item>
      <a-menu-item key="aboutUs">
        {{ $t("header.aboutUs") }}
      </a-menu-item>

      <a-menu-item key="login" v-if="!listenUserState">
        {{ $t("header.signIn") }}
      </a-menu-item>

      <a-sub-menu v-if="listenUserState">
        <span slot="title" class="submenu-title-wrapper">
          <a-icon type="menu-fold" />
          {{ $t("header.myAccount") }}
        </span>
        <a-menu-item key="myAccount">
          {{ $t("header.myAccount") }}
        </a-menu-item>

        <a-menu-item key="signout">
          {{ $t("header.signOut") }}
        </a-menu-item>
      </a-sub-menu>

      <!--            <a-sub-menu>-->
      <!--                <span slot="title" class="submenu-title-wrapper">-->
      <!--                    <a-icon type="setting"/>-->
      <!--                    {{ text }}</span>-->
      <!--                <a-menu-item key="setting:1" @click="changeLang('en', 'English')">-->
      <!--                    English-->
      <!--                </a-menu-item>-->
      <!--                <a-menu-item key="setting:2" @click="changeLang('zh', '中文')">-->
      <!--                    中文-->
      <!--                </a-menu-item>-->
      <!--            </a-sub-menu>-->
    </a-menu>
  </div>
</template>
<script>
import { getToken, setToken, removeToken } from "@/utils/auth";
export default {
  name: "Header",
  data() {
    return {
      current: ["home"],
      text: "English",
      account: "My Account",
      logoSrc: "../../assets/img/orca_logo.png",
      userStatus: false,
    };
  },
  computed: {
    haveTk: {
      get() {
        return getToken() !== undefined && getToken() !== "";
      },
      set() {},
    },
    listenUserState: {
      get() {
        console.log("token: " + this.$store.getters.token);
        return this.$store.getters.token;
      },
      set() {},
    },
  },
  watch: {
    // listenUserState: function (val, oldVal) {
    //     if (val == undefined || val == '') {
    //         this.userStatus = false
    //     } else {
    //         this.userStatus = true
    //     }
    //     console.log('val:' + val)
    //     console.log('oldVal:' + oldVal)
    // }
  },
  methods: {
    online() {
      console.log("tk:" + getToken());
      if (getToken() === undefined || getToken() === "") {
        return false;
      } else {
        return true;
      }
    },
    menuClick(item) {
      let path = item.key;
      if (path.indexOf("setting") !== -1) {
        return;
      }
      if (path === "signout") {
        this.$store
          .dispatch("LogOut")
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
        return;
      }
      //路由跳转事件
      this.$router.push("/" + path);
    },
    changeLang(lang, text) {
      this.$i18n.locale = lang;
      this.text = text;
    },
  },
};
</script>
<style>
.ant-menu-item-selected {
  /* color: #013289 !important; */
  /*border-bottom: 0px !important;*/
}
.ant-menu-submenu-selected {
  color: rgba(0, 0, 0, 0.65) !important;
  border-bottom: 0px !important;
}
.logoDiv {
  width: 175px;
  height: 64px;
  float: left;
  text-align: left;
}
#logoImg {
  height: 50%;
  margin: 0 15px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #0d0d0d;
  border-bottom: 2px solid #0d0d0d;
}
</style>
